import React from "react";
import { graphql } from "gatsby";
import CareerLinkBox from "../../components/career-link-box";
import PathwaysIntro from "../../components/pathways-intro";
import HeroPage from "../../components/hero-page";
import Pathways from "../../components/pathways";
import Seo from "../../components/seo";

const Agriculture = ({data}) => {


  return (
    <>
      <Seo
        title="Agriculture and Food Production, North Central Pennsylvania"
        description="Farming represents the backbone of Pennsylvania’s heritage and economy. The Agriculture and Food Production (AFP) cluster includes growing of grains and livestock, and the wholesale distribution of these products"
        featuredImage={data.opengraphImage.sharp.image}
      />

      <HeroPage
        image={data.heroImage.sharp.fluid}
        title="work"
        caption=""
        position="20%"
      />
      <main>
        <section className="page-intro">
          <div className="intro-title">
            <h2>Agriculture and Food Production</h2>
            <p>
              Farming represents the backbone of Pennsylvania’s heritage and
              economy.{" "}
            </p>
          </div>

          <div className="intro-text">
            <p>
              The Agriculture and Food Production (AFP) cluster begins with the
              growing of food crops, including grains and livestock, and ends
              with the wholesale distribution of these products.
            </p>

            <p>
              The cluster also includes support industries, such as refrigerated
              warehousing and fertilizer manufacturing, as well as public
              agencies that oversee agricultural programs.
            </p>
          </div>
        </section>

        <CareerLinkBox image={data.image.sharp.image} caption="" />
      </main>

      <PathwaysIntro
        industry="Agriculture and Food Production"
        image={data.pathwaysImage.sharp.image}
        caption={
          <span>
            Blackberry and Sage Market, Jefferson County
            <br />
            (image courtesy of{" "}
            <a href="https://pawilds.com" target="_new">
              PA Wilds
            </a>
            )
          </span>
        }
      />

      <Pathways pathway={data.pathways.pathway} />

    </>
  );
};

export const query = graphql`
    query {
      pathways: pathwaysYaml {
        pathway: agriculture {
          name
          associate {
            name
            occupations
            salary
            skills
          }
          diploma {
            name
            occupations
            salary
            skills
          }
          certificate {
            name
            occupations
            salary
            skills
          }
          bachelor {
            name
            occupations
            salary
            skills
          }
        }
      }

      image: file(
        relativePath: { eq: "zoe-schaeffer-ERVDg4pXUBE-unsplash.jpg" }
      ) {
        sharp: childImageSharp {
          image: gatsbyImageData
        }
      }

      pathwaysImage: file(
        relativePath: {
          eq: "JeffersonCounty-BlackberryAndSageMarket-MakingWraps-PAWilds2.jpg"
        }
      ) {
        sharp: childImageSharp {
          image: gatsbyImageData
        }
      }

      heroImage: file(
        relativePath: { eq: "ricardo-gomez-angel-ltDrvSoyllA-unsplash.jpg" }
      ) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      opengraphImage: file(relativePath: { eq: "ricardo-gomez-angel-ltDrvSoyllA-unsplash.jpg" }) {
        sharp: childImageSharp {
          image: gatsbyImageData(layout: FIXED, width: 1200)
        }
      }
    }
  `;

export default Agriculture;
